import { Injectable } from '@angular/core';
import { Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { CompanyStateService } from '@dougs/company/shared';
import { User } from '@dougs/user/dto';
import { USER_FLAG } from '@dougs/user/shared';
import { URL } from '../const/routing.const';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(
    private readonly router: Router,
    private readonly companyStateService: CompanyStateService,
  ) {}

  createUrl(url: string[], withCompanyId = true, navigationsExtras?: UrlCreationOptions): UrlTree {
    return this.router.createUrlTree(
      [URL.COMPANY_PREFIX, withCompanyId ? this.companyStateService.activeCompany.id.toString() : '', ...url],
      navigationsExtras,
    );
  }

  getRelevantOnboardingCreaUrl(user: User): URL {
    return user.flags.includes(USER_FLAG.CAN_ACCESS_OLD_LEGAL_CREATION_ONBOARDING_MODULE)
      ? URL.ONBOARDING_CREATION_OLD
      : URL.ONBOARDING_CREATION;
  }
}
