export enum URL {
  ACCOUNTING = 'accounting',
  ACCOUNTING_REVIEW = 'accounting-review',
  ACCOUNTING_SURVEY = 'accounting-survey',
  BASE = 'app',
  COCKPIT = 'cockpit',
  COMPANIES = 'companies',
  COMPANY_PREFIX = 'c',
  CREATION = 'creation',
  DECLARATION = 'declaration',
  ECOMMERCE_CONFIG = 'ecommerce-config',
  HOME = '/',
  INVESTMENT = 'investment',
  SALES_INVOICE = 'sales-invoice',
  OLD_SALES_INVOICE = 'invoicing',
  ME = 'me',
  TODO = 'todo',
  ONBOARDING_CREATION_OLD = 'onboarding-creation-legal',
  ONBOARDING_CREATION = 'onboarding-creation',
  OPERATIONS = 'operations',
  PARTNERSHIP = 'partnership',
  PERFORMANCE = 'performance',
  REFERRER_DASHBOARD = 'referrer-dashboard',
  RPG = 'rpg',
  SERVICE = 'services',
  SETTINGS = 'settings',
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  TASKS = 'tasks',
  VENDOR_INVOICE = 'vendor-invoice',
  ADMIN = 'admin',
}
